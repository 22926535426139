import React from 'react';

import { FocusableInput, FocusableButton, FocusableLink, FocusableTextarea } from './Controllers';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function Hp(props) {
  return (
    <div>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Normal Link
        </a>
        <FocusableLink
          focusPath="link-1"
          href="https://www.google.com"
        >www.google.com</FocusableLink>

        <FocusableButton focusPath="item-2"
          onClick={(e) => {
            alert(": )")
          }}
        >click me</FocusableButton>
        <FocusableInput
          name="asd"
          focusPath="item-3"
          />
        <FocusableTextarea
          focusPath="item-4"
        >Any text</FocusableTextarea>


        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
    </div>
  );
}

export default Hp;
