import React from 'react';
import logo from './logo.svg';
import './App.css';
import { withNavigation } from 'react-tv-navigation'

import Hp from './Hp';
import Page from './Page';

import { FocusableInput, FocusableButton, FocusableLink, FocusableTextarea, FocusableRouteLink } from './Controllers';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function App({currentFocusPath}) {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <FocusableRouteLink href="/">HP</FocusableRouteLink>
          <FocusableRouteLink href="page">Page 2</FocusableRouteLink>
        </header>

        <div>
          <Switch>
             <Route path="/page">
               <Page />
             </Route>
             <Route path="/">
               <Hp />
             </Route>
           </Switch>
        </div>
      </div>
    </Router>
  );
}

export default withNavigation(App);
